import {
  addCatalogToView,
  createView,
  deleteView,
  patchView,
  removeCatalogFromView,
} from "api/view";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router";
import useSWR from "swr";

import {
  CreateViewRequest,
  CreateViewResponse,
  PatchViewRequest,
  PatchViewResponse,
  View,
  ViewTuple,
} from "lib/view";

import { useAuth } from "./AuthContext";

export interface ViewState {
  viewId: number;
  error?: any;
  view?: View;
  isValidating: boolean;
  views?: ViewTuple[];
  refresh: () => Promise<void>;
  createView: (request: CreateViewRequest) => Promise<CreateViewResponse>;
  patchView: (request: PatchViewRequest) => Promise<PatchViewResponse>;
  deleteView: () => Promise<any>;
  addCatalogToView: (catalogId: number) => Promise<any>;
  removeCatalogFromView: (catalogId: number) => Promise<any>;
}

const initialState: ViewState = {
  viewId: 0,
  isValidating: false,
  refresh: () => Promise.reject(),
  createView: () => Promise.reject(),
  patchView: () => Promise.reject(),
  deleteView: () => Promise.reject(),
  addCatalogToView: () => Promise.reject(),
  removeCatalogFromView: () => Promise.reject(),
};

/**
 * ViewContext implements a React Context.
 */
export const ViewContext = createContext<ViewState>(initialState);

/**
 * Props of the ViewProvider component.
 */
interface ViewProviderProps {
  children: React.ReactNode;
}

/**
 * ViewProvider is a React Context Provider responsible for managing
 * the views.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const ViewProvider = (props: ViewProviderProps) => {
  let { viewId } = useParams();
  const { tenant } = useAuth();
  const enabledCustomerViewsConfig = tenant?.config.customer_views;

  const { data: views } = useSWR<ViewTuple[]>(
    `/ui/admin/views:select?blank=true${enabledCustomerViewsConfig ? "&showOnlyParents=true" : ""}`,
  );

  const fetchResult = useSWR<View>(() => {
    if (viewId && views) {
      return `/ui/admin/views/${viewId}`;
    }
    return null;
  });

  const refresh = useCallback(async () => {
    fetchResult.mutate();
  }, [fetchResult]);

  const doDeleteView = useCallback(async () => {
    const id = Number(viewId);
    if (id) {
      await fetchResult.mutate();
      return deleteView(id);
    }
    return Promise.reject();
  }, [viewId, fetchResult]);

  const doPatchView = useCallback(
    async (request: PatchViewRequest) => {
      const id = Number(viewId);
      if (id) {
        await fetchResult.mutate();
        return patchView(id, request);
      }
      return Promise.reject();
    },
    [viewId, fetchResult],
  );

  const doAddCatalogToView = useCallback(
    async (catalogId: number) => {
      const id = Number(viewId);
      if (id) {
        await fetchResult.mutate();
        return addCatalogToView(id, catalogId);
      }
      return Promise.reject();
    },
    [viewId, fetchResult],
  );

  const doRemoveCatalogFromView = useCallback(
    async (catalogId: number) => {
      const id = Number(viewId);
      if (id) {
        await fetchResult.mutate();
        return removeCatalogFromView(id, catalogId);
      }
      return Promise.reject();
    },
    [viewId, fetchResult],
  );

  const value = useMemo(() => {
    return {
      viewId: Number(viewId),
      error: fetchResult.error,
      view: fetchResult.data,
      isValidating: fetchResult.isValidating,
      views,
      refresh,
      createView,
      patchView: doPatchView,
      deleteView: doDeleteView,
      addCatalogToView: doAddCatalogToView,
      removeCatalogFromView: doRemoveCatalogFromView,
    };
  }, [
    viewId,
    fetchResult,
    views,
    refresh,
    doPatchView,
    doDeleteView,
    doAddCatalogToView,
    doRemoveCatalogFromView,
  ]);

  return <ViewContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by ViewProvider.
 * ViewContext returns.
 *
 * @returns ViewContext
 */
export const useView = () => {
  const context = useContext(ViewContext);
  if (context === undefined) {
    throw new Error(`useView must be used within ViewProvider`);
  }
  return context;
};
