import {
  AuthorizeAmazonCatalogResponse,
  Catalog,
  ConnectCatalogResponse,
  CreateAuthCodeResponse,
  CreateCatalogRequest,
  OpenAmazonAccountPageResponse,
  PatchCatalogRequest,
} from "lib/catalog";
import { TaskIDResponse } from "lib/task";

import client from "./httpClient";

/**
 * Enable a catalog.
 */
export const enableCatalog = async (catalogId: number): Promise<Catalog> => {
  const data = await client(
    "POST",
    `/ui/admin/catalogs/${catalogId}:enable`,
    {},
  );
  return data;
};

/**
 * Disable a catalog.
 */
export const disableCatalog = async (catalogId: number): Promise<Catalog> => {
  const data = await client(
    "POST",
    `/ui/admin/catalogs/${catalogId}:disable`,
    {},
  );
  return data;
};

/**
 * Purge a catalog.
 */
export const purgeCatalog = async (
  catalogId: number,
): Promise<TaskIDResponse> => {
  const data = await client(
    "POST",
    `/ui/admin/catalogs/${catalogId}:purge`,
    {},
  );
  return data;
};

/**
 * Trigger the import of a catalog.
 */
export const importCatalog = async (
  catalogId: number,
): Promise<TaskIDResponse> => {
  const data = await client(
    "POST",
    `/ui/admin/catalogs/${catalogId}:import`,
    {},
  );
  return data;
};

/**
 * Delete a catalog.
 */
export const deleteCatalog = async (
  catalogId: number,
): Promise<TaskIDResponse> => {
  const data = await client("DELETE", `/ui/admin/catalogs/${catalogId}`, {});
  return data;
};

/**
 * Create a new catalog.
 *
 * @param request DTO to create a new catalog
 * @returns CreateCatalogResponse
 */
export const createCatalog = async (
  request: CreateCatalogRequest,
): Promise<Catalog> => {
  const data = await client("POST", `/ui/admin/catalogs`, request);
  return data;
};

/**
 * Patch a catalog.
 *
 * @param catalogId ID of catalog to patch
 * @param request fields to patch
 * @returns PatchCatalogResponse
 */
export const patchCatalog = async (
  catalogId: number,
  request: PatchCatalogRequest,
): Promise<Catalog> => {
  const data = await client(
    "PATCH",
    `/ui/admin/catalogs/${catalogId}`,
    request,
  );
  return data;
};

/**
 * Open Amazon Business account associated with the catalog.
 *
 * @param id id of the catalog to connect
 * @returns OpenAmazonBusinessAccountCatalogResponse or undefined
 */
export const openAmazonAccountPage = async (
  id: number,
): Promise<OpenAmazonAccountPageResponse> => {
  const data = await client(
    "POST",
    `/ui/admin/catalogs/${id}:openAmazonAccount`,
    {},
  );
  return data;
};

/**
 * Connect a catalog with a marketplace like Amazon Business.
 *
 * @param id id of the catalog to connect
 * @returns ConnectCatalogResponse or undefined
 */
export const connectCatalog = async (
  id: number,
): Promise<ConnectCatalogResponse> => {
  const data = await client("POST", `/ui/admin/catalogs/${id}:connect`, {});
  return data;
};

/**
 * Disconnect a catalog from a marketplace like Amazon Business.
 *
 * @param id id of the catalog to disconnect
 * @returns Catalog
 */
export const disconnectCatalog = async (id: number): Promise<Catalog> => {
  const data = await client("POST", `/ui/admin/catalogs/${id}:disconnect`, {});
  return data;
};

/**
 * Create an Auth Code for a catalog.
 *
 * @param id id of the catalog to create the auth code for
 * @returns CreateAuthCodeResponse or undefined
 */
export const createAuthCodeForCatalog = async (
  id: number,
): Promise<CreateAuthCodeResponse> => {
  const data = await client(
    "POST",
    `/ui/admin/catalogs/${id}:createAuthCode`,
    {},
  );
  return data;
};

/**
 * Initiate the authorization of an Amazon catalog by means of an Auth Code.
 *
 * @param authCode authCode of the catalog to authorize
 * @param identity identity to be used in cXML From Header
 * @param sharedSecret shared secret for the cXML Header
 * @param poRequestUrl URL to send purchase order requests
 * @returns AuthorizeAmazonCatalogResponse or undefined
 */
export const authorizeAmazonCatalog = async (
  authCode: string,
  identity: string,
  sharedSecret: string,
  poRequestUrl: string,
): Promise<AuthorizeAmazonCatalogResponse> => {
  const data = await client("POST", "/ui/amazon/authorize", {
    authCode,
    identity,
    sharedSecret,
    poRequestUrl,
  });
  return data;
};
