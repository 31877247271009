import { SortDirection } from "@meplato/ui-kit";

import { View } from "lib/view";

export enum Mode {
  VIEW,
  NEW,
  EDIT,
}

export enum FilterParam {
  FreeTextSearch = "query",
}

export enum SortParam {
  Sort = "sort",
}

export enum SortColumn {
  Name = "name",
}

export interface Filters {
  searchQuery: string;
}

export interface Sort {
  column: SortColumn;
  direction: SortDirection;
}
export interface VendorCatalogValue {
  vendorId: number;
  catalogIds: number[];
}

export interface ViewFormInputs {
  parentId?: number;
  name: string;
  externalId: string;
  excludeUNSPSCs?: string[];
  vendors?: VendorCatalogValue[];
}

export type ViewFields = Omit<View, "id" | "tenantId"> & {
  created?: string;
  updated?: string;
};
