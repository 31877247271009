import { GuidedTourStatus } from "lib/guidedTour";
import { Config } from "lib/tenant";
import { Announcement } from "types/announcement";

import client from "./httpClient";

// -- Types --

/**
 * Profile of the current user
 */
export interface Profile {
  userId: number;
  email: string;
  name?: string;
  isRoot?: boolean;
  isAdmin?: boolean;
  isPunchout?: boolean;
  isRequisitioner?: boolean;
  isBuyer?: boolean;
  managerId?: number;
  managerName?: string;
  managerEmail?: string;
  viewId?: number;
  tenantId?: number;
  tenantName?: string;
  language?: string;
  amazonAccountVerified?: boolean;
  inPunchoutSession?: boolean;
  switchableTenants?: SwitchableTenant[];
  recentlyUsedTenants?: SwitchableTenant[];
  pinnedTenants?: SwitchableTenant[];
  guidedTourStatus?: GuidedTourStatus;
  announcement?: Announcement;
}

/**
 * Represents a tenant that a privileged user can switch to.
 */
export interface SwitchableTenant {
  id: number;
  name: string;
  selected?: boolean;
}

/**
 * User information
 */
export interface User {
  id: number;
  email: string;
  name?: string;
  isAdmin?: boolean;
  created: string;
}

export interface TenantResponse {
  id: number;
  config: Config;
  features: {
    // List of features are defined in the BE:
    // https://github.com/meplato/storefront/blob/main/internal/config/feature_flags.go
    storefront_in_marketplace?: boolean;
    disable_buyer_requesitioner_flow?: boolean;
    customer_views?: boolean;
    facet_search_v2?: boolean;
  };
}

// -- Sign in --

/**
 * Try to sign in the current user by username and password
 *
 * @param username username, e.g. an email address
 * @param password password in clear text
 * @returns Profile or null
 */
export const signin = async (
  username: string,
  password: string,
): Promise<Profile | undefined> => {
  const data = await client("POST", "/ui/signin", {
    username,
    password,
  });
  return data;
};

// -- Sign in options --

export interface SigninOptions {
  password?: PasswordSigninOptions;
  saml?: SAMLSigninOptions;
}

interface PasswordSigninOptions {
  enabled: boolean;
}

interface SAMLSigninOptions {
  enabled: boolean;
  signinURL: string;
}

export interface ResetPassword {
  success: boolean;
}

export interface ValidateResetTokenResponse {
  username: string;
  expired: boolean;
  created_at: string;
  expired_at: string;
}
export interface ResetPasswordInput {
  password: string;
  confirmPassword: string;
}

/**
 * Try to find sign in options for the current user
 *
 * @param username username, e.g. an email address
 * @returns Profile or null
 */
export const signinOptions = async (
  username: string,
): Promise<SigninOptions | undefined> => {
  const data = await client("POST", `/ui/signin/_options`, {
    username,
  });
  return data;
};

// -- Sign out --

/**
 * Sign out the current user.
 *
 * @returns Response from backend's sign out process
 */
export const signout = async (): Promise<any> => {
  const data = await client("POST", "/ui/signout", null);
  return data;
};

// -- Me --

/**
 * Return the current user's profile.
 *
 * @returns Profile or null
 */
export const profile = async (): Promise<Profile | undefined> => {
  const data = await client("GET", "/ui/profile", {});
  return data;
};

// -- User --

/**
 * Return information about the current user.
 *
 * @returns User or null
 */
export const currentUser = async (): Promise<User | undefined> => {
  const data = await client("GET", "/ui/user", {});
  return data;
};

/**
 * Switch to a different tenant.
 *
 * @returns Id of new tenant
 */
export const switchTenant = async (
  newTenantId: number,
): Promise<SwitchTenantResponse> => {
  const data = await client("PUT", `/ui/tenant?tenantId=${newTenantId}`, {});
  return data;
};

/**
 * Response from the switchTenant function.
 */
export interface SwitchTenantResponse {
  redirectUrl: string;
}

/**
 * Response from the getAmazonUserAutoCreate function.
 */
export interface AmazonUserAutoCreateResponse {
  id: number;
  amazon: {
    autoCreateUsers: boolean;
    usersGroupId: string;
  };
}

/**
 * Create Amazon user.
 */
export const createAmazonUser = async (): Promise<void> => {
  const data = await client("POST", `/ui/amazon/users`, {});
  return data;
};

/**
 * Get Amazon user auto creation config.
 */
export const getAmazonUserAutoCreate =
  async (): Promise<AmazonUserAutoCreateResponse> => {
    const data = await client("GET", "/ui/amazon/catalog", {});
    return data;
  };

/**
 * PIN Tenant.
 */
export const pinTenant = async (
  tenantId: number,
): Promise<SwitchTenantResponse> => {
  const data = await client("PUT", `/ui/profile/tenants/${tenantId}/pin`, {});
  return data;
};

/**
 * PIN Tenant.
 */
export const unPinTenant = async (
  tenantId: number,
): Promise<SwitchTenantResponse> => {
  const data = await client(
    "DELETE",
    `/ui/profile/tenants/${tenantId}/pin`,
    {},
  );
  return data;
};

/**
 * Try to reset password
 *
 * @param email email, e.g. an email address
 * @returns response or null
 */
export const resetPassword = async (email: string): Promise<ResetPassword> => {
  const data = await client("POST", "/ui/resetPassword", {
    username: email,
  });
  return data;
};

export const changePassword = async (
  token: string,
  password: string,
): Promise<any> => {
  const data = await client("POST", `/ui/changePassword`, {
    token: token,
    password: password,
  });
  return data;
};
