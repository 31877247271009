import { Filters, Sort } from "pages/admin/views/types";
import { urlParamsToFilters, urlParamsToSort } from "pages/admin/views/utils";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

import { ViewsSearchResponse } from "lib/view";

import { ViewContext } from "./ViewContext";

interface Context {
  views: ViewsSearchResponse;
  filters: Filters;
  isSearchOpen?: boolean;
  page: number;
  sort?: Partial<Sort>;
  openSearch: (open: boolean) => void;
  resetPage: () => void;
  setPage: Dispatch<SetStateAction<number>>;
  setViews: Dispatch<SetStateAction<ViewsSearchResponse>>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunction = () => {};

const defaultContext: Context = {
  views: { total: 0, data: [] },
  filters: urlParamsToFilters(new URLSearchParams()),
  page: 1,
  openSearch: emptyFunction,
  resetPage: emptyFunction,
  setPage: emptyFunction,
  setViews: emptyFunction,
};

const ViewsContext = createContext<Context | undefined>(undefined);
ViewContext.displayName = "Views Context";

export const ViewsProvider = ({ children }: PropsWithChildren) => {
  const [sp] = useSearchParams();
  const filters = urlParamsToFilters(sp);
  const sort = urlParamsToSort(sp);
  const [isSearchOpen, setIsSearchOpen] = useState(
    Boolean(filters.searchQuery),
  );
  const [page, setPage] = useState(defaultContext.page);
  const [views, setViews] = useState<ViewsSearchResponse>(defaultContext.views);

  const resetPage = useCallback(() => setPage(defaultContext.page), [setPage]);

  const value: Context = {
    views,
    filters,
    isSearchOpen,
    page,
    sort,
    openSearch: setIsSearchOpen,
    resetPage,
    setPage,
    setViews,
  };

  return (
    <ViewsContext.Provider value={value}>{children}</ViewsContext.Provider>
  );
};

export const useViewsContext = () => {
  const context = useContext(ViewsContext);
  if (context === undefined) {
    throw new Error(`useViews must be used within ViewsProvider`);
  }

  return context;
};
