import { ClientsSearchResponse } from "lib/client";
import { createContext, useContext, useMemo } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";

export interface ClientsState {
  error?: any;
  clients?: ClientsSearchResponse;
  isValidating: boolean;
}

/**
 * ClientsContext implements a React Context.
 */
export const ClientsContext = createContext<ClientsState | undefined>(
  undefined
);

/**
 * Props of the ClientsProvider component.
 */
interface ClientsProviderProps {
  children: React.ReactNode;
}

/**
 * ClientsProvider is a React Context Provider responsible for managing clients.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const ClientsProvider = (props: ClientsProviderProps) => {
  const [searchParams] = useSearchParams();
  const sp = createSearchParams(searchParams);
  const { error, data, isValidating } = useSWR<ClientsSearchResponse>(
    `/ui/admin/clients?${sp.toString()}`
  );

  const value = useMemo(() => {
    return {
      error,
      clients: data,
      isValidating,
    };
  }, [error, data, isValidating]);

  return <ClientsContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by ClientsProvider.
 * ClientsContext returns.
 *
 * @returns ClientsContext
 */
export const useClients = () => {
  const context = useContext(ClientsContext);
  if (context === undefined) {
    throw new Error(`useClients must be used within ClientsProvider`);
  }
  return context;
};
