import React from "react";
import { Route, Routes } from "react-router";

const BookmarkListDetail = React.lazy(() => import("./BookmarkListDetail"));
const BookmarksList = React.lazy(() => import("./BookmarkList"));

export default function BookmarksPage() {
  return (
    <Routes>
      <Route index element={<BookmarksList />} />
      <Route path="/:id" element={<BookmarkListDetail />} />
    </Routes>
  );
}
