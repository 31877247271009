import "@fontsource-variable/inter";
import "@fontsource/roboto-mono";
import { Provider as TooltipProvider } from "@radix-ui/react-tooltip";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";

/** @fontsource/inter
 * We need still need to import fontsource-variable separately as the font files are not bundled with ui-kit
 */
import * as sentry from "utils/sentry";

import { ErrorBoundary } from "components/ErrorBoundary";
import { FeatureFlagsProvider } from "contexts/FeatureFlagsContext";
import { ThemeProvider } from "contexts/ThemeContext";

import App from "./App";
import fetcher from "./api/swrClient";
import { AuthProvider } from "./contexts/AuthContext";
import { SiteProvider } from "./contexts/SiteContext";
import "./i18n/config";
import reportWebVitals from "./reportWebVitals";
import "./styles/tailwind.css";

sentry.init();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <SWRConfig
        value={{
          fetcher,
        }}
      >
        <BrowserRouter>
          <SiteProvider>
            <AuthProvider>
              <FeatureFlagsProvider>
                <TooltipProvider delayDuration={0}>
                  <ThemeProvider>
                    <App />
                  </ThemeProvider>
                </TooltipProvider>
              </FeatureFlagsProvider>
            </AuthProvider>
          </SiteProvider>
        </BrowserRouter>
      </SWRConfig>
    </React.StrictMode>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
