import { createContext, useContext, useMemo } from "react";

const initialState = {};

/**
 * OrderContext implements a React Context.
 */
export const OrderContext = createContext(initialState);

/**
 * Props of the OrderProvider component.
 */
interface OrderProviderProps {
  children: React.ReactNode;
}

/**
 * OrderProvider is a React Context Provider responsible for managing
 * purchase orders.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const OrderProvider = (props: OrderProviderProps) => {
  const value = useMemo(() => {
    return {};
  }, []);

  return <OrderContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by OrderProvider.
 *
 * @returns OrderContext
 */
export const useOrders = () => {
  const context = useContext(OrderContext);
  if (context === undefined) {
    throw new Error(`useOrders must be used within OrderProvider`);
  }
  return context;
};
