import { Tenant } from "lib/tenant";
import React, { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router";
import useSWR from "swr";

export interface TenantAdminsState {
  tenant?: Tenant;
}

const initialState: TenantAdminsState = {};

export const TenantAdminsContext =
  createContext<TenantAdminsState>(initialState);

type TenantAdminsProviderProps = React.PropsWithChildren;

export const TenantAdminsProvider = ({
  children,
}: TenantAdminsProviderProps) => {
  const { tenantId } = useParams();

  const { data: tenant } = useSWR<Tenant>(`/ui/admin/tenants/${tenantId}`);

  const value = useMemo(() => {
    return {
      tenant,
    };
  }, [tenant]);

  return (
    <TenantAdminsContext.Provider value={value}>
      {children}
    </TenantAdminsContext.Provider>
  );
};

export const useTenantAdmins = () => {
  const context = useContext(TenantAdminsContext);
  if (context === undefined) {
    throw new Error(`useTenantAdmins must be used within TenantAdminsProvider`);
  }
  return context;
};
