import { EventsSearchResponse } from "lib/event";
import { createContext, useContext, useMemo } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";

export interface EventsState {
  error?: any;
  events?: EventsSearchResponse;
  isValidating: boolean;
}

/**
 * EventsContext implements a React Context.
 */
export const EventsContext = createContext<EventsState | undefined>(undefined);

/**
 * Props of the EventsProvider component.
 */
interface EventsProviderProps {
  children: React.ReactNode;
}

/**
 * EventsProvider is a React Context Provider responsible for managing events.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const EventsProvider = (props: EventsProviderProps) => {
  const [searchParams] = useSearchParams();
  const sp = createSearchParams(searchParams);
  const { error, data, isValidating, mutate } = useSWR<EventsSearchResponse>(
    `/ui/admin/events?${sp.toString()}`,
    {
      refreshInterval: 10 * 1000, // 10 seconds
    }
  );

  const value = useMemo(() => {
    return {
      error,
      events: data,
      isValidating,
      refresh: mutate,
    };
  }, [error, data, isValidating, mutate]);

  return <EventsContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by EventsProvider.
 * EventsContext returns.
 *
 * @returns EventsContext
 */
export const useEvents = () => {
  const context = useContext(EventsContext);
  if (context === undefined) {
    throw new Error(`useEvents must be used within EventsProvider`);
  }
  return context;
};
