import { VendorsSearchResponse } from "lib/vendor";
import { createContext, useContext, useMemo } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";

export interface VendorsState {
  error?: any;
  vendors?: VendorsSearchResponse;
  isValidating: boolean;
}

/**
 * VendorsContext implements a React Context.
 */
export const VendorsContext = createContext<VendorsState | undefined>(
  undefined
);

/**
 * Props of the VendorsProvider component.
 */
interface VendorsProviderProps {
  children: React.ReactNode;
}

/**
 * VendorsProvider is a React Context Provider responsible for managing vendors.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const VendorsProvider = (props: VendorsProviderProps) => {
  const [searchParams] = useSearchParams();
  const sp = createSearchParams(searchParams);

  const { error, data, isValidating } = useSWR<VendorsSearchResponse>(
    `/ui/admin/vendors?${sp.toString()}`
  );

  const value = useMemo(() => {
    return {
      error,
      vendors: data,
      isValidating,
    };
  }, [error, data, isValidating]);

  return <VendorsContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by VendorsProvider.
 * VendorsContext returns.
 *
 * @returns VendorsContext
 */
export const useVendors = () => {
  const context = useContext(VendorsContext);
  if (context === undefined) {
    throw new Error(`useVendors must be used within VendorsProvider`);
  }
  return context;
};
