import { TasksSearchResponse } from "lib/task";
import { createContext, useContext, useMemo } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";

export interface TasksState {
  error?: any;
  tasks?: TasksSearchResponse;
  isValidating: boolean;
}

/**
 * TasksContext implements a React Context.
 */
export const TasksContext = createContext<TasksState | undefined>(undefined);

/**
 * Props of the TasksProvider component.
 */
interface TasksProviderProps {
  children: React.ReactNode;
}

/**
 * TasksProvider is a React Context Provider responsible for managing tasks.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const TasksProvider = (props: TasksProviderProps) => {
  const [searchParams] = useSearchParams();
  const sp = createSearchParams(searchParams);
  const { error, data, isValidating } = useSWR<TasksSearchResponse>(
    `/ui/admin/tasks?${sp.toString()}`,
    {
      refreshInterval: 10 * 1000, // 10 seconds
    }
  );

  const value = useMemo(() => {
    return {
      error,
      tasks: data,
      isValidating,
    };
  }, [error, data, isValidating]);

  return <TasksContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by TasksProvider.
 * TasksContext returns.
 *
 * @returns TasksContext
 */
export const useTasks = () => {
  const context = useContext(TasksContext);
  if (context === undefined) {
    throw new Error(`useTasks must be used within TasksProvider`);
  }
  return context;
};
