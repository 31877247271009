import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { CONTACT_SUPPORT_EMAIL } from "utils/constants";

import FullPageError from "components/FullPageError";

type Props = React.PropsWithChildren;

export const ErrorBoundary = ({ children }: Props) => {
  const { t } = useTranslation();

  const fallback = (
    <FullPageError
      title={t("errorBoundary.title")}
      message={t("errorBoundary.message", {
        contactSupportEmail: CONTACT_SUPPORT_EMAIL,
      })}
    />
  );

  return (
    <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>
  );
};
