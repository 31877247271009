import { Currency } from "lib/currency";
import { Language } from "lib/language";
import { Region } from "lib/region";
import { createContext, useContext, useMemo } from "react";
import useSWR from "swr";

interface CommonData {
  languages?: Language[];
  regions?: Region[];
  currencies?: Currency[];
}

/**
 * CommonDataContext implements a React Context.
 */
export const CommonDataContext = createContext<CommonData | undefined>(
  undefined
);

/**
 * Props of the CommonDataProvider component.
 */
interface CommonDataProviderProps {
  children: React.ReactNode;
}

/**
 * CommonDataProvider is a React Context Provider responsible for providing
 * information about data like languages, regions, and locales.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const CommonDataProvider = (props: CommonDataProviderProps) => {
  const { data: languages } = useSWR<Language[]>("/ui/admin/languages");
  const { data: regions } = useSWR<Region[]>("/ui/admin/regions");
  const { data: currencies } = useSWR<Currency[]>("/ui/admin/currencies");

  const value = useMemo(
    () => ({
      languages,
      regions,
      currencies,
    }),
    [languages, regions, currencies]
  );

  return <CommonDataContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the list of languages provided by CommonDataProvider.
 *
 * @returns Array of languages or undefined
 */
export const useLanguages = () => {
  const context = useContext(CommonDataContext);
  if (context === undefined) {
    throw new Error(`useLanguages must be used within CommonDataProvider`);
  }
  return context.languages;
};

/**
 * Helper to get access to the list of regions provided by CommonDataProvider.
 *
 * @returns Array of regions or undefined
 */
export const useRegions = () => {
  const context = useContext(CommonDataContext);
  if (context === undefined) {
    throw new Error(`useRegions must be used within CommonDataProvider`);
  }
  return context.regions;
};

/**
 * Helper to get access to the list of currencies provided by CommonDataProvider.
 *
 * @returns Array of currencies or undefined
 */
export const useCurrencies = () => {
  const context = useContext(CommonDataContext);
  if (context === undefined) {
    throw new Error(`useCurrencies must be used within CommonDataProvider`);
  }
  return context.currencies;
};
