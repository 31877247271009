import * as guidedTourApi from "api/guidedTour";
import {
  Suspense,
  createContext,
  lazy,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tour } from "shepherd.js";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { urls } from "utils/urls";

import { useCart } from "contexts/CartContext";
import { GuidedTourStatus } from "lib/guidedTour";
import { GuidedTourStepId } from "types/guidedTour";

import { useAuth } from "./AuthContext";

interface ContextValue {
  status?: GuidedTourStatus;
  isReady?: boolean;
  currentStep?: GuidedTourStepId;
  start?: () => void;
}

const GuidedTour = lazy(() =>
  import("../components/GuidedTour").then((module) => ({
    default: module.GuidedTour,
  })),
);

export const GuidedTourContext = createContext<ContextValue>({});

export const GuidedTourProvider = ({ children, ...rest }: Props) => {
  const [isReady, setReady] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const pageTourRef = useRef<Tour>();
  const { profile, isAdmin, isRoot } = useAuth();
  const [status, setStatus] = useState<GuidedTourStatus | undefined>(
    profile?.guidedTourStatus,
  );
  const [currentStep, setCurrentStep] = useState<GuidedTourStepId>(
    GuidedTourStepId.Welcome,
  );
  const { cart, removeCartItem } = useCart();

  const navigateToStep = (direction: "next" | "back") => {
    return () => {
      const currentStep = pageTourRef.current?.getCurrentStep();

      if (currentStep) {
        const index = pageTourRef.current?.steps.findIndex(
          (step) => step.id === currentStep.id,
        );

        if (index !== undefined && index > -1) {
          const navigateDirection =
            direction === "next" ? index + 1 : index - 1;
          const step = pageTourRef.current?.steps[navigateDirection];

          if (step) {
            setCurrentStep(step.id as GuidedTourStepId);

            pageTourRef.current?.show(step.id);
          }
        }
      }
    };
  };

  const next = navigateToStep("next");
  const back = navigateToStep("back");

  const start = () => {
    if (status === "started" && currentStep === GuidedTourStepId.Welcome) {
      pageTourRef.current?.start();
    }
  };

  const addHeaderOverlay = () => {
    document.body.classList.add("tour-is-active");
  };

  const removeHeaderOverlay = () => {
    document.body.classList.remove("tour-is-active");
  };

  const handleTourCancel = () => {
    const outroStepId = GuidedTourStepId.Contact;

    setCurrentStep(outroStepId);
    setStatus("skipped");

    if (location?.pathname !== urls.Landing) {
      navigate(urls.Landing);
    }

    // Trigger in the next tick, so that the hide / show events are triggered in order by Shepherd
    setTimeout(() => {
      pageTourRef.current?.show(outroStepId);
    }, 0);
  };

  const cancelTourRef = useRef(handleTourCancel);

  const value: ContextValue = {
    status,
    isReady,
    currentStep,
    start,
  };

  const cleanupCart = async () => {
    const item = cart?.vendorItems?.map(({ items }) => items)?.flat()?.[0];

    if (item) {
      await removeCartItem(item.id);
    }
  };

  const { trigger } = useSWRMutation(
    "/ui/guidedTourStatus",
    async (
      _key,
      {
        arg,
      }: {
        arg: { status: GuidedTourStatus };
      },
    ) => {
      return guidedTourApi.updateStatus(arg.status);
    },
  );

  const complete = async () => {
    removeHeaderOverlay();
    await trigger({ status: status === "skipped" ? "skipped" : "completed" });
    mutate("/ui/profile");
  };

  const handleTourReady = (tour: Tour) => {
    pageTourRef.current = tour;

    pageTourRef.current.on("cancel", handleTourCancel);
    pageTourRef.current.on("step:show", addHeaderOverlay);
    pageTourRef.current.on("step:hide", removeHeaderOverlay);

    setReady(true);
  };

  const shouldShowGuidedTour = status === "started" && !isAdmin && !isRoot;

  useEffect(() => {
    if (currentStep === GuidedTourStepId.Finish) {
      complete();
    }

    // Cleanup cart
    if (currentStep === GuidedTourStepId.Contact) {
      cleanupCart();
    }
  }, [currentStep]);

  useEffect(() => {
    setStatus(profile?.guidedTourStatus);
  }, [profile?.guidedTourStatus, location]);

  useEffect(() => {
    if (pageTourRef.current) {
      pageTourRef.current.off("cancel", cancelTourRef.current);

      cancelTourRef.current = handleTourCancel;
      pageTourRef.current.on("cancel", cancelTourRef.current);
    }
  }, [location]);

  return (
    <GuidedTourContext.Provider value={value} {...rest}>
      {children}

      {shouldShowGuidedTour && (
        <Suspense>
          <GuidedTour
            tour={pageTourRef.current}
            onNext={next}
            onBack={back}
            onReady={handleTourReady}
          />
        </Suspense>
      )}
    </GuidedTourContext.Provider>
  );
};

export const useGuidedTour = () => {
  const context = useContext(GuidedTourContext);
  if (context === undefined) {
    throw new Error(`useGuidedTour must be used within GuidedTourProvider`);
  }
  return context;
};

interface Props {
  children: React.ReactNode;
}
