import * as React from "react";

/**
 * DelaySpinner component.
 */
const DelaySpinner: React.FC = (): JSX.Element => {
  return (
    <div className="flex h-screen items-center justify-center space-x-2 animate-pulse delay-1000">
      <div className="w-8 h-8 bg-gray-300 rounded-full"></div>
      <div className="w-8 h-8 bg-gray-300 rounded-full"></div>
      <div className="w-8 h-8 bg-gray-300 rounded-full"></div>
    </div>
  );
};

export default DelaySpinner;
