import { Mode } from "@meplato/ui-kit";

import {
  AssignTenantAdminRequestPayload,
  CreateTenantRequest,
  PatchTenantRequest,
  Tenant,
} from "lib/tenant";

import client from "./httpClient";

/**
 * Delete a tenant.
 */
export const deleteTenant = async (tenantId: number): Promise<any> => {
  const data = await client("DELETE", `/ui/admin/tenants/${tenantId}`, {});
  return data;
};

/**
 * Undo tenant deletion.
 */

export const undeleteTenant = async (tenantId: number) => {
  const data = await client("POST", `/ui/admin/tenants/${tenantId}:undelete`);
  return data;
};

/**
 * Create a new tenant.
 *
 * @param request DTO to create a new tenant
 * @returns Tenant
 */
export const createTenant = async (
  request: CreateTenantRequest,
): Promise<Tenant> => {
  const data = await client("POST", `/ui/admin/tenants`, request);
  return data;
};

/**
 * Patch a tenant.
 *
 * @param tenantId ID of tenant to patch
 * @param request fields to patch
 * @returns Tenant
 */
export const patchTenant = async (
  tenantId: number,
  request: PatchTenantRequest,
): Promise<Tenant> => {
  const data = await client("PATCH", `/ui/admin/tenants/${tenantId}`, request);
  return data;
};

export const assignAdmin = async (payload: AssignTenantAdminRequestPayload) => {
  const data = await client("POST", "/ui/admin/accounts", payload);
  return data;
};

/**
 * Delete tenant logo.
 */
export const deleteTenantLogo = async (
  tenantId: number,
  mode: Mode,
): Promise<any> => {
  const data = await client(
    "DELETE",
    `/ui/admin/tenants/${tenantId}/logo/${mode}`,
    {},
  );
  return data;
};

/**
 * Delete tenant footer logo.
 */
export const deleteTenantFooterLogo = async (
  tenantId: number,
  mode: Mode,
): Promise<any> => {
  const data = await client(
    "DELETE",
    `/ui/admin/tenants/${tenantId}/footer_logo/${mode}`,
    {},
  );
  return data;
};
