import { createClient, deleteClient, patchClient } from "api/client";
import {
  Client,
  CreateClientRequest,
  CreateClientResponse,
  PatchClientRequest,
} from "lib/client";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";

export interface ClientState {
  clientId: number;
  error?: any;
  client?: Client;
  isValidating: boolean;
  refresh: () => Promise<void>;
  createClient: (request: CreateClientRequest) => Promise<CreateClientResponse>;
  patchClient: (request: PatchClientRequest) => Promise<Client>;
  deleteClient: () => Promise<any>;
}

const initialState: ClientState = {
  clientId: 0,
  isValidating: false,
  refresh: () => Promise.reject(),
  createClient: (request: CreateClientRequest) => Promise.reject(),
  patchClient: (request: PatchClientRequest) => Promise.reject(),
  deleteClient: () => Promise.reject(),
};

/**
 * ClientContext implements a React Context.
 */
export const ClientContext = createContext<ClientState>(initialState);

/**
 * Props of the ClientProvider component.
 */
interface ClientProviderProps {
  children: React.ReactNode;
}

/**
 * ClientProvider is a React Context Provider responsible for managing
 * a single client.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const ClientProvider = (props: ClientProviderProps) => {
  let { clientId } = useParams();

  const fetchResult = useSWR<Client>(
    clientId && `/ui/admin/clients/${clientId}`
  );

  const refresh = useCallback(async () => {
    fetchResult.mutate();
  }, [fetchResult]);

  const doPatchClient = useCallback(
    async (request: PatchClientRequest) => {
      const id = Number(clientId);
      if (id) {
        return patchClient(id, request);
      }
      return Promise.reject();
    },
    [clientId]
  );

  const doDeleteClient = useCallback(async () => {
    const id = Number(clientId);
    if (id) {
      return deleteClient(id);
    }
    return Promise.reject();
  }, [clientId]);

  const value = useMemo(() => {
    return {
      clientId: Number(clientId),
      error: fetchResult.error,
      client: fetchResult.data,
      isValidating: fetchResult.isValidating,
      refresh,
      createClient,
      patchClient: doPatchClient,
      deleteClient: doDeleteClient,
    };
  }, [clientId, fetchResult, refresh, doPatchClient, doDeleteClient]);

  return <ClientContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by ClientProvider.
 * ClientContext returns.
 *
 * @returns ClientContext
 */
export const useClient = () => {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error(`useClient must be used within ClientProvider`);
  }
  return context;
};
