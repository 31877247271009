import { Theme } from "@meplato/ui-kit/lib";
import { AuthenticationError } from "api/error";
import { createContext, useContext, useMemo } from "react";
import useSWR from "swr";

import DelaySpinner from "components/DelaySpinner";
import FullPageError from "components/FullPageError";
import { Site } from "lib/site";

type SiteType = Site & { noLogoExist: boolean };

export const defaultSite: Site = {
  theme: "webridge",
  logo: undefined,
  logoAltText: "",
  logoDarkMode: undefined,
  footerLogo: undefined,
  footerLogoDarkMode: "",
  footerCopyrightCompany: "",
  footerPrivacyPolicy: "",
  footerImprint: "",
  defaultLandingPageHeroImage: false,
};

/**
 * SiteContext implements a React Context.
 */
export const SiteContext = createContext<Site>(defaultSite);

/**
 * Props of the SiteProvider component.
 */
interface SiteProviderProps {
  children: React.ReactNode;
}

/**
 * SiteProvider is a React Context Provider responsible for providing
 * metadata about the application.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const SiteProvider = (props: SiteProviderProps) => {
  const { data, error } = useSWR<Site>("/ui/site");

  const allowedThemes: Theme[] = ["webridge", "coupa"];
  const theme =
    data?.theme && allowedThemes.includes(data.theme as Theme)
      ? data.theme
      : defaultSite.theme;

  const value = useMemo(
    () => ({
      theme: theme,
      logo: data?.logo || defaultSite.logo,
      logoDarkMode: data?.logoDarkMode || defaultSite.logoDarkMode,
      logoAltText: data?.logoAltText || defaultSite.logoAltText,
      topBarText: data?.topBarText || defaultSite.topBarText,
      topBarHref: data?.topBarHref || defaultSite.topBarHref,
      footerLogo: data?.footerLogo || defaultSite.footerLogo,
      footerLogoDarkMode:
        data?.footerLogoDarkMode || defaultSite.footerLogoDarkMode,
      footerCopyrightCompany:
        data?.footerCopyrightCompany || defaultSite.footerCopyrightCompany,
      footerPrivacyPolicy:
        data?.footerPrivacyPolicy || defaultSite.footerPrivacyPolicy,
      footerImprint: data?.footerImprint || defaultSite.footerImprint,
      defaultLandingPageHeroImage:
        data?.defaultLandingPageHeroImage ||
        defaultSite.defaultLandingPageHeroImage,
    }),
    [data],
  );

  if (!data && !error) {
    return <DelaySpinner />;
  }

  if (error && !(error instanceof AuthenticationError)) {
    return (
      <FullPageError
        title="Loading metadata failed"
        message={`We were unable to load site metadata. The original error message is: ${error}.`}
      />
    );
  }

  return <SiteContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by SiteProvider.
 * SiteContext returns e.g.:
 *
 * - theme (string)
 * - logo (string)
 * - logoAltText (string)
 *
 * @returns SiteContext
 */
export const useSite = () => {
  const context = useContext(SiteContext);
  if (context === undefined) {
    throw new Error(`useSite must be used within SiteProvider`);
  }
  return context;
};
