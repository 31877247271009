import { updateUserSettings } from "api/settings";
import { UpdateUserSettingsRequest, UserSettings } from "lib/userSettings";
import { createContext, useCallback, useContext, useMemo } from "react";
import useSWR from "swr";

export interface UserSettingsState {
  error?: any;
  settings?: UserSettings;
  isValidating: boolean;
  update: (request: UpdateUserSettingsRequest) => Promise<UserSettings>;
}

const initialState: UserSettingsState = {
  isValidating: false,
  update: () => Promise.reject(),
};

/**
 * UserSettingsContext implements a React Context.
 */
export const UserSettingsContext =
  createContext<UserSettingsState>(initialState);

/**
 * Props of the UserSettingsProvider component.
 */
interface UserSettingsProviderProps {
  children: React.ReactNode;
}

/**
 * UserSettingsProvider is a React Context Provider responsible for
 * managing the current user's settings.
 *
 * @param props User settings props
 * @returns React Functional Component
 */
export const UserSettingsProvider = (props: UserSettingsProviderProps) => {
  const fetch = useSWR<UserSettings>("/ui/settings");

  const update = useCallback(
    async (request: UpdateUserSettingsRequest) => {
      const newSettings = await updateUserSettings(request);
      await fetch.mutate(newSettings);
      return Promise.resolve(newSettings);
    },
    [fetch]
  );

  const value = useMemo(() => {
    return {
      error: fetch.error,
      settings: fetch.data,
      isValidating: fetch.isValidating,
      update,
    };
  }, [fetch, update]);

  return <UserSettingsContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by UserSettingsProvider.
 * UserSettingsContext returns.
 *
 * @returns UserSettingsContext
 */
export const useUserSettings = () => {
  const context = useContext(UserSettingsContext);
  if (context === undefined) {
    throw new Error(`useUserSettings must be used within UserSettingsProvider`);
  }
  return context;
};
