import Cookies from "js-cookie";

import { Error } from "./error";

// process.env.REACT_APP_BACKEND_URL || "";
const baseURL = "";

/**
 * A client to interact with the backend via REST API, correctly handling
 * all headers, CSRF etc.
 *
 * @param method e.g. GET or POST
 * @param endpoint URL excluding the hostname part
 * @param data Data to post to the backend
 * @returns Promise returning JSON data
 */
const client = async (
  method: string,
  endpoint: string,
  data: any = {},
): Promise<Error | any> => {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  const csrfToken = Cookies.get("csrf_token");
  if (csrfToken) {
    headers["X-CSRF-Token"] = csrfToken;
  }
  const config: RequestInit = {
    method,
    headers,
    credentials: "same-origin",
  };
  if (method !== "GET") {
    config.body = JSON.stringify(data);
  }
  return fetch(`${baseURL}${endpoint}`, config)
    .then(async (response) => {
      // We don't want to parse when the HTTP status code is 204, since there is no response / content
      const data = response.status === 204 ? "" : await response.json();

      if (!response.ok) {
        return Promise.reject({
          ...data,
          __meta: {
            statusCode: response.status,
          },
        });
      }

      return data;
    })
    .catch((err) => {
      if (err.error) {
        return Promise.reject({
          code: err.error.code,
          message: err.error.message,
          fields: err.error.fields,
          __meta: err?.__meta,
        });
      }
      return Promise.reject(err);
    });
};
export default client;
