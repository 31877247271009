/**
 * Error message
 */
export interface Error {
  code: number;
  message: string;
  __meta: {
    statusCode: number;
  };
}

/**
 * Application error
 */
export class ApplicationError extends Error {
  constructor(message: string, code: number | undefined) {
    super(message);
    this._code = code || 0;
    Object.setPrototypeOf(this, ApplicationError.prototype);
  }

  private _code: number;
  public get code(): number {
    return this._code;
  }
}

/**
 * Authentication error
 */
export class AuthenticationError extends Error {
  constructor(message: string, code: number | undefined) {
    super(message);
    this._code = code || 0;
    Object.setPrototypeOf(this, AuthenticationError.prototype);
  }

  private _code: number;
  public get code(): number {
    return this._code;
  }
}

/**
 * Extract error message from error, or return an empty string.
 *
 * @param error Error to get message from
 * @returns Error message or an empty string
 */
export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }
  if (
    error instanceof Object &&
    "message" in error &&
    typeof error.message === "string"
  ) {
    return error.message || "";
  }
  return String(error);
}
