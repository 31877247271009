import {
  CreateVendorRequest,
  CreateVendorResponse,
  PatchVendorRequest,
  PatchVendorResponse,
} from "lib/vendor";
import client from "./httpClient";

/**
 * Delete a vendor.
 */
export const deleteVendor = async (vendorId: number): Promise<any> => {
  const data = await client("DELETE", `/ui/admin/vendors/${vendorId}`, {});
  return data;
};

/**
 * Create a new vendor.
 *
 * @param request DTO to create a new vendor
 * @returns CreateVendorResponse
 */
export const createVendor = async (
  request: CreateVendorRequest
): Promise<CreateVendorResponse> => {
  const data = await client("POST", `/ui/admin/vendors`, request);
  return data;
};

/**
 * Patch a vendor.
 *
 * @param vendorId ID of vendor to patch
 * @param request fields to patch
 * @returns PatchVendorResponse
 */
export const patchVendor = async (
  vendorId: number,
  request: PatchVendorRequest
): Promise<PatchVendorResponse> => {
  const data = await client("PATCH", `/ui/admin/vendors/${vendorId}`, request);
  return data;
};
