import {
  CreateViewRequest,
  CreateViewResponse,
  PatchViewRequest,
  PatchViewResponse,
  ResetToParentViewResponse,
} from "lib/view";

import client from "./httpClient";

/**
 * Add a catalog to a view.
 */
export const addCatalogToView = async (
  viewId: number,
  catalogId: number,
): Promise<any> => {
  const data = await client(
    "POST",
    `/ui/admin/views/${viewId}/catalogs/${catalogId}`,
    {},
  );
  return data;
};

/**
 * Remove a catalog from a view.
 */
export const removeCatalogFromView = async (
  viewId: number,
  catalogId: number,
): Promise<any> => {
  const data = await client(
    "DELETE",
    `/ui/admin/views/${viewId}/catalogs/${catalogId}`,
    {},
  );
  return data;
};

/**
 * Delete a view.
 */
export const deleteView = async (viewId: number): Promise<any> => {
  const data = await client("DELETE", `/ui/admin/views/${viewId}`, {});
  return data;
};

/**
 * Create a new view.
 *
 * @param request DTO to create a new view
 * @returns CreateViewResponse
 */
export const createView = async (
  request: CreateViewRequest,
): Promise<CreateViewResponse> => {
  const data = await client("POST", `/ui/admin/views`, request);
  return data;
};

/**
 * Patch a view.
 *
 * @param viewId ID of view to patch
 * @param request fields to patch
 * @returns PatchViewResponse
 */
export const patchView = async (
  viewId: number,
  request: PatchViewRequest,
): Promise<PatchViewResponse> => {
  const data = await client("PATCH", `/ui/admin/views/${viewId}`, request);
  return data;
};

/**
 * Reset to parent view.
 *
 * @returns void
 */
export const resetToParentView = async (
  viewId: number,
): Promise<ResetToParentViewResponse> => {
  const data = await client("POST", `/ui/admin/views/${viewId}:reset`, {});
  return data;
};
