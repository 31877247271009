import { CatalogsSearchResponse } from "lib/catalog";
import { createContext, useContext, useMemo } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";

export interface CatalogsState {
  error?: any;
  catalogs?: CatalogsSearchResponse;
  isValidating: boolean;
}

/**
 * CatalogsContext implements a React Context.
 */
export const CatalogsContext = createContext<CatalogsState | undefined>(
  undefined
);

/**
 * Props of the CatalogsProvider component.
 */
interface CatalogsProviderProps {
  children: React.ReactNode;
}

/**
 * CatalogsProvider is a React Context Provider responsible for managing catalogs.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const CatalogsProvider = (props: CatalogsProviderProps) => {
  const [searchParams] = useSearchParams();
  const sp = createSearchParams(searchParams);
  const { error, data, isValidating } = useSWR<CatalogsSearchResponse>(
    `/ui/admin/catalogs?${sp.toString()}`
  );

  const value = useMemo(() => {
    return {
      error,
      catalogs: data,
      isValidating,
    };
  }, [error, data, isValidating]);

  return <CatalogsContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by CatalogsProvider.
 * CatalogsContext returns.
 *
 * @returns CatalogsContext
 */
export const useCatalogs = () => {
  const context = useContext(CatalogsContext);
  if (context === undefined) {
    throw new Error(`useCatalogs must be used within CatalogsProvider`);
  }
  return context;
};
