import {
  createUser,
  deleteUser,
  patchUser,
  removeBuyersPrivilege,
} from "api/user";
import { TaskIDResponse } from "lib/task";
import {
  CreateUserRequest,
  PatchUserRequest,
  User,
  UserTuple,
  RemoveBuyersResponse,
} from "lib/user";
import { ViewTuple } from "lib/view";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router";
import useSWR from "swr";

export interface UserState {
  userId: number;
  error?: any;
  user?: User;
  isValidating: boolean;
  views?: ViewTuple[];
  refresh: () => Promise<void>;
  deleteUser: () => Promise<TaskIDResponse>;
  createUser: (request: CreateUserRequest) => Promise<User>;
  patchUser: (request: PatchUserRequest) => Promise<User>;
  removeBuyersPrivilege: () => Promise<RemoveBuyersResponse>;
}

const initialState: UserState = {
  userId: 0,
  isValidating: false,
  refresh: () => Promise.reject(),
  deleteUser: () => Promise.reject(),
  createUser: () => Promise.reject(),
  patchUser: () => Promise.reject(),
  removeBuyersPrivilege: () => Promise.reject(),
};

/**
 * UserContext implements a React Context.
 */
export const UserContext = createContext<UserState>(initialState);

/**
 * Props of the UserProvider component.
 */
interface UserProviderProps {
  children: React.ReactNode;
}

/**
 * UserProvider is a React Context Provider responsible for managing
 * the users.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const UserProvider = (props: UserProviderProps) => {
  let { userId } = useParams();

  const { data: views } = useSWR<ViewTuple[]>(`/ui/admin/views:select`);

  const {
    data: user,
    isValidating,
    error,
    mutate,
  } = useSWR<User>(userId && views ? `/ui/admin/users/${userId}` : null);

  const refresh = useCallback(async () => {
    mutate();
  }, [mutate]);

  const doDeleteUser = useCallback(async () => {
    const id = Number(userId);
    if (id) {
      return deleteUser(id);
    }
    return Promise.reject();
  }, [userId]);

  const doPatchUser = useCallback(
    async (request: PatchUserRequest) => {
      const id = Number(userId);
      if (id) {
        return patchUser(id, request);
      }
      return Promise.reject();
    },
    [userId],
  );

  const doRemoveBuyersPrivilege = useCallback(async () => {
    return removeBuyersPrivilege();
  }, []);

  const value = useMemo(() => {
    return {
      userId: Number(userId),
      error: error,
      user,
      isValidating,
      views,
      refresh,
      deleteUser: doDeleteUser,
      createUser,
      patchUser: doPatchUser,
      removeBuyersPrivilege: doRemoveBuyersPrivilege,
    };
  }, [
    userId,
    error,
    user,
    isValidating,
    views,
    refresh,
    doDeleteUser,
    doPatchUser,
    doRemoveBuyersPrivilege,
  ]);

  return <UserContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by UserProvider.
 * UserContext returns.
 *
 * @returns UserContext
 */
export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within UserProvider`);
  }
  return context;
};
