export enum GuidedTourStepId {
  Welcome = "intro:welcome",
  LandingSearch = "intro:search",
  BrowseSearch = "search:browse",
  BuyBox = "productDetails:buybox",
  AddProductToCart = "productDetails:buyboxAddToCart",
  Cart = "cart:summary",
  Contact = "outro:contact",
  Finish = "outro:finish",
}
