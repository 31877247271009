export enum Locale {
  de = "de",
  en = "en",
  tr = "tr",
  es = "es",
  ptBR = "pt-BR",
}

export const allLanguages = new Map([
  [Locale.de, { locale: Locale.de, displayName: "Deutsch" }],
  [Locale.en, { locale: Locale.en, displayName: "English" }],
  [Locale.tr, { locale: Locale.tr, displayName: "Türkçe" }],
  [Locale.es, { locale: Locale.es, displayName: "Español" }],
  [Locale.ptBR, { locale: Locale.ptBR, displayName: "Português" }],
]);
