import { CreateUserRequest, PatchUserRequest, User } from "lib/user";
import client from "./httpClient";

/**
 * Delete a user.
 */
export const deleteUser = async (userId: number): Promise<any> => {
  const data = await client("DELETE", `/ui/admin/users/${userId}`, {});
  return data;
};

/**
 * Create a new user.
 *
 * @param request DTO to create a new user
 * @returns User
 */
export const createUser = async (request: CreateUserRequest): Promise<User> => {
  const data = await client("POST", `/ui/admin/users`, request);
  return data;
};

/**
 * Patch a user.
 *
 * @param userId ID of user to patch
 * @param request fields to patch
 * @returns User
 */
export const patchUser = async (
  userId: number,
  request: PatchUserRequest
): Promise<User> => {
  const data = await client("PATCH", `/ui/admin/users/${userId}`, request);
  return data;
};

export const removeBuyersPrivilege = async (): Promise<any> => {
  const data = await client("POST", `/ui/admin/users/convertBuyersToPunchoutUsers`, {});
  return data;
};
