import Cookies from "js-cookie";

import { ApplicationError, AuthenticationError } from "./error";

// process.env.REACT_APP_BACKEND_URL || "";
const baseURL = "";

const fetcher = async <JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> => {
  const overrides: HeadersInit = {};
  const csrfToken = Cookies.get("csrf_token");
  if (csrfToken) {
    overrides["X-CSRF-Token"] = csrfToken;
  }
  if (!init) {
    init = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }
  init.headers = {
    ...init.headers,
    ...overrides,
  };
  if (!init.credentials) {
    init.credentials = "same-origin";
  }

  if (typeof input === "string") {
    input = `${baseURL}${input}`;
  }
  const res = await fetch(input, init);
  if (!res.ok) {
    const errorBody = await res.json();
    if (errorBody?.error?.message) {
      if (res.status === 401) {
        throw new AuthenticationError(errorBody.error.message, res.status);
      }
      throw new ApplicationError(errorBody.error.message, res.status);
    }
    const error = new Error("Unable to fetch data");
    throw error;
  }

  return res.json();
};

export default fetcher;
