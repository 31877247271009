import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import useLocalStorage from "hooks/useLocalStorage";

export interface ShoppingSession {
  keywords: string;
  setKeywords: Dispatch<SetStateAction<string>>;
  lastSearchUrl?: string;
  startSearch: (keywords: string) => void;
  viewMode: ViewMode;
  switchViewMode: (mode: ViewMode) => void;
}

const noop = () => undefined;

export type ViewMode = "grid" | "list";
export const initialData: ShoppingSession = {
  keywords: "",
  setKeywords: noop,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  startSearch: async (keywords: string) => {},
  viewMode: "grid",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  switchViewMode: (mode: ViewMode) => {},
};

/**
 * ShoppingContext implements a React Context.
 */
export const ShoppingContext = createContext<ShoppingSession>(initialData);
ShoppingContext.displayName = "ShoppingContext";

/**
 * Props of the ShoppingProvider component.
 */
interface ShoppingProviderProps {
  children: React.ReactNode;
}

/**
 * ShoppingProvider is a React Context Provider responsible for providing
 * metadata about the current shopping session.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const ShoppingProvider = (props: ShoppingProviderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [keywords, setKeywords] = useState<string>(
    () => searchParams.get("query") || "",
  );
  const [lastSearchUrl, setLastSearchUrl] = useLocalStorage(
    "lastSearchUrl",
    "/search",
  );
  const storedViewMode = localStorage.getItem("viewMode") || "grid";
  const [viewMode, setViewMode] = React.useState<ViewMode>(
    storedViewMode as ViewMode,
  );

  // Remember the last search URL
  useEffect(() => {
    try {
      if (location.pathname === "/search" && location.search) {
        setLastSearchUrl(location.pathname + location.search);
      } else {
        // Empty the search input value
        const pathArray = location.pathname.slice(1).split("/");
        if (pathArray && pathArray.length > 0) {
          // Except the product details
          if (pathArray[0] !== "products") {
            setKeywords("");
          }
        }
      }
    } catch (e) {
      console.error("Error while setting last search URL:", e);
    }
  }, [location, setLastSearchUrl]);

  const startSearch = useCallback(
    (keywords: string) => {
      // Forget most of the existing query parameters, e.g. page and facets
      const sp = createSearchParams();
      sp.set("query", keywords);
      setKeywords(keywords);

      // Keep some, like the provider tab
      if (searchParams.has("provider")) {
        sp.set("provider", searchParams.get("provider")!);
      }

      if (location.pathname !== "/search") {
        navigate(`/search?${sp.toString()}`);
      } else {
        setSearchParams(sp);
        window.scrollTo({ top: 0, left: 0 });
      }
    },
    [searchParams, setKeywords, setSearchParams, navigate, location],
  );

  const switchViewMode = useCallback(
    (mode: ViewMode) => {
      localStorage.setItem("viewMode", mode);
      setViewMode(mode);
    },
    [setViewMode],
  );

  const value = useMemo(
    () => ({
      keywords,
      setKeywords,
      lastSearchUrl,
      startSearch: (keywords: string) => {
        setKeywords(keywords);
        startSearch(keywords);
      },
      viewMode,
      switchViewMode,
    }),
    [
      keywords,
      setKeywords,
      lastSearchUrl,
      startSearch,
      switchViewMode,
      viewMode,
    ],
  );

  return <ShoppingContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by ShoppingProvider.
 * ShoppingContext returns e.g.:
 *
 * - keywords (string)
 * - startSearch (keywords: string) => Promise<any>
 *
 * @returns ShoppingContext
 */
export const useShopping = () => {
  const context = useContext(ShoppingContext);
  if (context === undefined) {
    throw new Error(`useShopping must be used within ShoppingProvider`);
  }
  return context;
};
