import { UsersSearchResponse } from "lib/user";
import { createContext, useContext, useMemo } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";

export interface UsersState {
  error?: any;
  users?: UsersSearchResponse;
  isValidating: boolean;
}

/**
 * UsersContext implements a React Context.
 */
export const UsersContext = createContext<UsersState | undefined>(undefined);

/**
 * Props of the UsersProvider component.
 */
interface UsersProviderProps {
  children: React.ReactNode;
}

/**
 * UsersProvider is a React Context Provider responsible for managing users.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const UsersProvider = (props: UsersProviderProps) => {
  const [searchParams] = useSearchParams();
  const sp = createSearchParams(searchParams);
  const { error, data, isValidating } = useSWR<UsersSearchResponse>(
    `/ui/admin/users?${sp.toString()}`
  );

  const value = useMemo(() => {
    return {
      error,
      users: data,
      isValidating,
    };
  }, [error, data, isValidating]);

  return <UsersContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by UsersProvider.
 * UsersContext returns.
 *
 * @returns UsersContext
 */
export const useUsers = () => {
  const context = useContext(UsersContext);
  if (context === undefined) {
    throw new Error(`useUsers must be used within UsersProvider`);
  }
  return context;
};
