import {
  Client,
  CreateClientRequest,
  CreateClientResponse,
  PatchClientRequest,
} from "lib/client";
import httpClient from "./httpClient";

/**
 * Delete a client.
 */
export const deleteClient = async (clientId: number): Promise<any> => {
  const data = await httpClient("DELETE", `/ui/admin/clients/${clientId}`, {});
  return data;
};

/**
 * Create a new client.
 *
 * @param request DTO to create a new client
 * @returns Client
 */
export const createClient = async (
  request: CreateClientRequest
): Promise<CreateClientResponse> => {
  const data = await httpClient("POST", `/ui/admin/clients`, request);
  return data;
};

/**
 * Patch a client.
 *
 * @param clientId ID of client to patch
 * @param request fields to patch
 * @returns Client
 */
export const patchClient = async (
  clientId: number,
  request: PatchClientRequest
): Promise<Client> => {
  const data = await httpClient(
    "PATCH",
    `/ui/admin/clients/${clientId}`,
    request
  );
  return data;
};
