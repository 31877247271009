import {
  connectCatalog,
  createAuthCodeForCatalog,
  createCatalog,
  deleteCatalog,
  disableCatalog,
  disconnectCatalog,
  enableCatalog,
  importCatalog,
  openAmazonAccountPage,
  patchCatalog,
  purgeCatalog,
} from "api/catalog";
import {
  Catalog,
  CatalogEvent,
  ConnectCatalogResponse,
  CreateAuthCodeResponse,
  CreateCatalogRequest,
  OpenAmazonAccountPageResponse,
  PatchCatalogRequest,
} from "lib/catalog";
import { Currency } from "lib/currency";
import { Language } from "lib/language";
import { Region } from "lib/region";
import { TaskIDResponse } from "lib/task";
import { VendorTuple } from "lib/vendor";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router";
import useSWR from "swr";

export interface CatalogState {
  catalogId: number;
  error?: any;
  catalog?: Catalog;
  isValidating: boolean;
  timeline?: CatalogEvent[];
  languages?: Language[];
  regions?: Region[];
  currencies?: Currency[];
  vendors?: VendorTuple[];
  refresh: () => Promise<void>;
  enableCatalog: () => Promise<Catalog>;
  disableCatalog: () => Promise<Catalog>;
  purgeCatalog: () => Promise<TaskIDResponse>;
  importCatalog: () => Promise<TaskIDResponse>;
  deleteCatalog: () => Promise<TaskIDResponse>;
  createCatalog: (request: CreateCatalogRequest) => Promise<Catalog>;
  patchCatalog: (request: PatchCatalogRequest) => Promise<Catalog>;
  openAmazonAccountPage: () => Promise<OpenAmazonAccountPageResponse>;
  connectCatalog: () => Promise<ConnectCatalogResponse>;
  disconnectCatalog: () => Promise<Catalog>;
  createAuthCode: () => Promise<CreateAuthCodeResponse>;
}

const initialState: CatalogState = {
  catalogId: 0,
  isValidating: false,
  refresh: () => Promise.reject(),
  enableCatalog: () => Promise.reject(),
  disableCatalog: () => Promise.reject(),
  purgeCatalog: () => Promise.reject(),
  importCatalog: () => Promise.reject(),
  deleteCatalog: () => Promise.reject(),
  createCatalog: () => Promise.reject(),
  patchCatalog: () => Promise.reject(),
  openAmazonAccountPage: () => Promise.reject(),
  connectCatalog: () => Promise.reject(),
  disconnectCatalog: () => Promise.reject(),
  createAuthCode: () => Promise.reject(),
};

/**
 * CatalogContext implements a React Context.
 */
export const CatalogContext = createContext<CatalogState>(initialState);

/**
 * Props of the CatalogProvider component.
 */
interface CatalogProviderProps {
  children: React.ReactNode;
}

/**
 * CatalogProvider is a React Context Provider responsible for managing
 * the catalogs.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const CatalogProvider = (props: CatalogProviderProps) => {
  let { catalogId } = useParams();

  const languages = useSWR<Language[]>(`/ui/admin/languages`);
  const regions = useSWR<Region[]>(`/ui/admin/regions`);
  const currencies = useSWR<Currency[]>(`/ui/admin/currencies`);
  const vendors = useSWR<VendorTuple[]>(`/ui/admin/vendors:select`);

  const fetchResult = useSWR<Catalog>(() => {
    if (
      catalogId &&
      languages.data &&
      regions.data &&
      currencies.data &&
      vendors.data
    ) {
      return `/ui/admin/catalogs/${catalogId}`;
    }
    return null;
  });

  const timelineResult = useSWR<CatalogEvent[]>(() => {
    if (
      catalogId &&
      languages.data &&
      regions.data &&
      currencies.data &&
      vendors.data
    ) {
      return `/ui/admin/catalogs/${catalogId}/timeline`;
    }
    return null;
  });

  const refresh = useCallback(async () => {
    fetchResult.mutate();
    timelineResult.mutate();
  }, [fetchResult, timelineResult]);

  const doEnableCatalog = useCallback(async () => {
    const id = Number(catalogId);
    if (id) {
      await fetchResult.mutate();
      await timelineResult.mutate();
      return enableCatalog(id);
    }
    return Promise.reject();
  }, [catalogId, fetchResult, timelineResult]);

  const doDisableCatalog = useCallback(async () => {
    const id = Number(catalogId);
    if (id) {
      await fetchResult.mutate();
      await timelineResult.mutate();
      return disableCatalog(id);
    }
    return Promise.reject();
  }, [catalogId, fetchResult, timelineResult]);

  const doPurgeCatalog = useCallback(async () => {
    const id = Number(catalogId);
    if (id) {
      await fetchResult.mutate();
      await timelineResult.mutate();
      return purgeCatalog(id);
    }
    return Promise.reject();
  }, [catalogId, fetchResult, timelineResult]);

  const doImportCatalog = useCallback(async () => {
    const id = Number(catalogId);
    if (id) {
      await fetchResult.mutate();
      await timelineResult.mutate();
      return importCatalog(id);
    }
    return Promise.reject();
  }, [catalogId, fetchResult, timelineResult]);

  const doDeleteCatalog = useCallback(async () => {
    const id = Number(catalogId);
    if (id) {
      await fetchResult.mutate();
      await timelineResult.mutate();
      return deleteCatalog(id);
    }
    return Promise.reject();
  }, [catalogId, fetchResult, timelineResult]);

  const doPatchCatalog = useCallback(
    async (request: PatchCatalogRequest) => {
      const id = Number(catalogId);
      if (id) {
        await fetchResult.mutate();
        await timelineResult.mutate();
        return patchCatalog(id, request);
      }
      return Promise.reject();
    },
    [catalogId, fetchResult, timelineResult]
  );

  const doOpenAmazonAccountPage = useCallback(async () => {
    const id = Number(catalogId);
    if (id) {
      return openAmazonAccountPage(id);
    }
    return Promise.reject();
  }, [catalogId]);

  const doConnectCatalog = useCallback(async () => {
    const id = Number(catalogId);
    if (id) {
      await fetchResult.mutate();
      await timelineResult.mutate();
      return connectCatalog(id);
    }
    return Promise.reject();
  }, [catalogId, fetchResult, timelineResult]);

  const doDisconnectCatalog = useCallback(async () => {
    const id = Number(catalogId);
    if (id) {
      await fetchResult.mutate();
      await timelineResult.mutate();
      return disconnectCatalog(id);
    }
    return Promise.reject();
  }, [catalogId, fetchResult, timelineResult]);

  const doCreateAuthCode = useCallback(async () => {
    const id = Number(catalogId);
    if (id) {
      await fetchResult.mutate();
      await timelineResult.mutate();
      return createAuthCodeForCatalog(id);
    }
    return Promise.reject();
  }, [catalogId, fetchResult, timelineResult]);

  const value = useMemo(() => {
    return {
      catalogId: Number(catalogId),
      error: fetchResult.error,
      catalog: fetchResult.data,
      isValidating: fetchResult.isValidating,
      timeline: timelineResult?.data,
      languages: languages?.data,
      regions: regions?.data,
      currencies: currencies?.data,
      vendors: vendors?.data,
      refresh,
      enableCatalog: doEnableCatalog,
      disableCatalog: doDisableCatalog,
      purgeCatalog: doPurgeCatalog,
      importCatalog: doImportCatalog,
      deleteCatalog: doDeleteCatalog,
      createCatalog,
      patchCatalog: doPatchCatalog,
      openAmazonAccountPage: doOpenAmazonAccountPage,
      connectCatalog: doConnectCatalog,
      disconnectCatalog: doDisconnectCatalog,
      createAuthCode: doCreateAuthCode,
    };
  }, [
    catalogId,
    fetchResult,
    timelineResult,
    languages,
    regions,
    currencies,
    vendors,
    refresh,
    doEnableCatalog,
    doDisableCatalog,
    doPurgeCatalog,
    doImportCatalog,
    doDeleteCatalog,
    doPatchCatalog,
    doOpenAmazonAccountPage,
    doConnectCatalog,
    doDisconnectCatalog,
    doCreateAuthCode,
  ]);

  return <CatalogContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by CatalogProvider.
 * CatalogContext returns.
 *
 * @returns CatalogContext
 */
export const useCatalog = () => {
  const context = useContext(CatalogContext);
  if (context === undefined) {
    throw new Error(`useCatalog must be used within CatalogProvider`);
  }
  return context;
};
