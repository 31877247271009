import * as Sentry from "@sentry/react";
import * as auth from "api/auth";
import { getAmazonUserAutoCreate } from "api/auth";
import { AuthenticationError } from "api/error";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useSWR from "swr";

import DelaySpinner from "components/DelaySpinner";
import FullPageError from "components/FullPageError";

/**
 * Session represents an end-user interacting with the frontend.
 */
export interface Session {
  isAuthenticated: boolean;
  isRoot: boolean;
  isAdmin: boolean;
  isPunchout: boolean;
  isRequisitioner: boolean;
  isBuyer: boolean;
  profile?: auth.Profile;
  amazonUserAutoCreation?: boolean;
  tenant?: auth.TenantResponse;

  // InPunchoutSession specifies whether the user has entered Storefront
  // through OCI/cXML punchout.
  inPunchoutSession: boolean;
  createAmazonUser: () => Promise<void>;
  signin: (
    username: string,
    password: string,
  ) => Promise<auth.Profile | undefined>;
  signinOptions: (username: string) => Promise<auth.SigninOptions | undefined>;
  resetPassword: (username: string) => Promise<auth.ResetPassword | undefined>;
  signout: () => Promise<any>;
}

export const initialSession: Session = {
  isAuthenticated: false,
  isRoot: false,
  isAdmin: false,
  isPunchout: false,
  isRequisitioner: false,
  isBuyer: false,
  inPunchoutSession: false,
  createAmazonUser: auth.createAmazonUser,
  signin: auth.signin,
  signinOptions: auth.signinOptions,
  resetPassword: auth.resetPassword,
  signout: auth.signout,
};

/**
 * AuthContext implements a React Context.
 */
export const AuthContext = createContext<Session>(initialSession);

/**
 * Props of the AuthProvider component.
 */
interface AuthProviderProps {
  children: React.ReactNode;
}

/**
 * AuthProvider is a React Context Provider responsible for providing the
 * current user to the application.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const AuthProvider = (props: AuthProviderProps) => {
  const { data, error, mutate } = useSWR<auth.Profile>("/ui/profile", {
    shouldRetryOnError: false,
    revalidateIfStale: false,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  });

  // get amazon users auto creation
  const [autoCreateUsers, setAutoCreateUsers] = useState<boolean>(false);
  useEffect(() => {
    if (autoCreateUsers === false) {
      getAmazonUserAutoCreate().then(
        (data: auth.AmazonUserAutoCreateResponse) => {
          if (data) {
            setAutoCreateUsers(data.amazon.autoCreateUsers);
          }
        },
      );
    }
  }, [autoCreateUsers]);

  // create amazon user automatically
  const createAmazonUser = useCallback(
    () =>
      auth.createAmazonUser().then(async () => {
        await mutate();
      }),
    [mutate],
  );

  // Sign the current user in
  const signin = useCallback(
    (email: string, password: string) =>
      auth.signin(email, password).then((profile) => mutate(profile, true)),
    [mutate],
  );

  // Sign options for the current user
  const signinOptions = useCallback(
    (email: string) => auth.signinOptions(email),
    [],
  );

  // Reset user password
  const resetPassword = useCallback(
    (username: string) => auth.resetPassword(username),
    [],
  );

  // Sign the current user out
  const signout = useCallback(() => {
    return auth.signout().finally(() => mutate(undefined, true));
  }, [mutate]);

  const isAuthenticated = !!data && !error;

  const tenant = useSWR<auth.TenantResponse>(
    isAuthenticated ? "/ui/tenant" : null,
  );
  const showSpinner =
    (!data && !error) || (isAuthenticated && tenant?.isLoading);

  const value = useMemo(
    () => ({
      isAuthenticated,
      isRoot: data?.isRoot || false,
      isAdmin: data?.isAdmin || false,
      isPunchout: data?.isPunchout || false,
      isRequisitioner: data?.isRequisitioner || false,
      isBuyer: data?.isBuyer || false,
      inPunchoutSession: data?.inPunchoutSession || false,
      profile: data,
      amazonUserAutoCreation: autoCreateUsers,
      createAmazonUser,
      signin,
      signinOptions,
      resetPassword,
      signout,
      tenant: tenant.data,
    }),
    [
      signin,
      signinOptions,
      resetPassword,
      signout,
      createAmazonUser,
      data,
      getAmazonUserAutoCreate,
      autoCreateUsers,
      tenant.data,
      error,
    ],
  );

  // Initialize context for sentry
  useEffect(() => {
    if (data) {
      // Add custom for filtering it in Sentry UI
      Sentry.setTag("tenantId", data.tenantId);

      Sentry.setUser({ id: data.userId, language: data.language });
    }
  }, [data]);

  if (showSpinner) {
    return <DelaySpinner />;
  }

  if (error && !(error instanceof AuthenticationError)) {
    return (
      <FullPageError
        title="Authentication failed"
        message={`We were unable to authenticate your account. The original error message is: ${error}`}
      />
    );
  }

  return <AuthContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by AuthProvider.
 * AuthContext returns e.g.:
 *
 * - isAuthenticated (boolean)
 * - profile (auth.Profile or null)
 * - signin method
 * - signout method
 *
 * @returns AuthContext
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within AuthProvider`);
  }
  return context;
};
