import { Mode, Theme, UikitProvider } from "@meplato/ui-kit";
import React, { createContext, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";

import { useSite } from "./SiteContext";

interface ThemeState {
  theme: Theme;
  mode: Mode;
}

export const ThemeContext = createContext<ThemeState>({
  theme: "webridge",
  mode: "light",
});

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isAdminPanel = location.pathname.includes("/admin");

  const { theme: siteTheme } = useSite();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode: Mode = useMemo(() => {
    return isAdminPanel ? "light" : prefersDarkMode ? "dark" : "light";
  }, [isAdminPanel, prefersDarkMode]);
  const theme: Theme = siteTheme || "webridge";

  return (
    <ThemeContext.Provider value={{ theme, mode }}>
      <UikitProvider
        theme={theme}
        mode={mode}
        translationKeys={{
          "Table.HeaderCell.SortDropdown.ascending": t(
            "sort_dropdown.ascending.label",
          ),
          "Table.HeaderCell.SortDropdown.descending": t(
            "sort_dropdown.descending.label",
          ),
          "InputField.Multiline.preview.collapsed": t("common.show_more"),
          "InputField.Multiline.preview.expanded": t("common.show_less"),
        }}
      >
        {children}
      </UikitProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
