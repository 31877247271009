import {
  addProductToCart,
  orderCurrentCart,
  removeCartItem,
  updateCartItemQuantity,
} from "api/cart";
import { AuthenticationError } from "api/error";
import { createContext, useCallback, useContext, useMemo } from "react";
import useSWR, { useSWRConfig } from "swr";

import DelaySpinner from "components/DelaySpinner";
import FullPageError from "components/FullPageError";
import {
  AddToCartResponse,
  Cart,
  OrderCartResponse,
  RemoveCartItemResponse,
  UpdateCartItemQuantityResponse,
} from "types/cart";

export interface CartState {
  cart: Cart;
  isLoading: boolean;
  addProductToCart: (
    productId: string,
    offerId: string,
    quantity: number,
  ) => Promise<AddToCartResponse>;
  updateCartItemQuantity: (
    id: number,
    quantity: number,
  ) => Promise<UpdateCartItemQuantityResponse>;
  removeCartItem: (id: number) => Promise<RemoveCartItemResponse>;
  orderCart: (buyerId: number) => Promise<OrderCartResponse>;
}

const emptyCart: Cart = {
  id: 0,
  status: "open",
  numItems: 0,
  totalsByCurrency: [],
  totals: {
    amount: 0.0,
    currency: "",
    formatted: "",
  },
  created: "",
  updated: "",
  items: [],
  vendorItems: [],
};

const initialState: CartState = {
  cart: emptyCart,
  isLoading: false,
  addProductToCart: (productId: string, offerId: string, quantity: number) =>
    Promise.reject(),
  updateCartItemQuantity: (id: number, quantity: number) => Promise.reject(),
  removeCartItem: (id: number) => Promise.reject(),
  orderCart: (buyerId: number) => Promise.reject(),
};

/**
 * CartContext implements a React Context.
 */
export const CartContext = createContext<CartState>(initialState);

/**
 * Props of the CartProvider component.
 */
interface CartProviderProps {
  children: React.ReactNode;
}

/**
 * CartProvider is a React Context Provider responsible for managing
 * the current shopping cart.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const CartProvider = (props: CartProviderProps) => {
  const { mutate: revalidate } = useSWRConfig();
  const { data, error, isValidating, mutate } = useSWR<Cart>("/ui/cart");

  const addProduct = useCallback(
    async (productId: string, offerId: string, quantity: number) => {
      return addProductToCart(productId, offerId, quantity).then((response) => {
        mutate(response.cart);
        revalidate("/ui/status");
        return response;
      });
    },
    [mutate, revalidate],
  );

  const updateQuantity = useCallback(
    async (id: number, quantity: number) => {
      return updateCartItemQuantity(id, quantity).then((response) => {
        mutate(response.cart);
        revalidate("/ui/status");
        return response;
      });
    },
    [mutate, revalidate],
  );

  const removeItem = useCallback(
    async (id: number) => {
      return removeCartItem(id).then((response) => {
        mutate(response.cart);
        revalidate("/ui/status");
        return response;
      });
    },
    [mutate, revalidate],
  );

  const orderCart = useCallback(
    async (buyerId: number) => {
      return orderCurrentCart(buyerId).finally(() => {
        revalidate("/ui/status");
      });
    },
    [revalidate],
  );

  const value = useMemo(() => {
    return {
      cart: data || initialState.cart,
      isLoading: isValidating,
      addProductToCart: addProduct,
      updateCartItemQuantity: updateQuantity,
      removeCartItem: removeItem,
      orderCart: orderCart,
    };
  }, [data, isValidating, addProduct, updateQuantity, removeItem, orderCart]);

  if (!data && !error) {
    return <DelaySpinner />;
  }

  if (error && !(error instanceof AuthenticationError)) {
    return (
      <FullPageError
        title="Failed to load current shopping cart"
        message={`We were unable to load the current shopping cart. The original error message is: ${error}.`}
      />
    );
  }

  return <CartContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by CartProvider.
 * CartContext returns e.g.:
 *
 * - numItems (number)
 *
 * @returns CartContext
 */
export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error(`useCart must be used within CartProvider`);
  }
  return context;
};
