import React from "react";
import { Route, Routes } from "react-router";

import { useAuth } from "contexts/AuthContext";

const AnnouncementsNew = React.lazy(() => import("./AnnouncementsNew"));
const AnnouncementsList = React.lazy(() => import("./AnnouncementsList"));
const AnnouncementsDetail = React.lazy(() => import("./AnnouncementsDetail"));

export default function Announcements() {
  const { isRoot, isAdmin } = useAuth();
  const canManageAnnouncements = isAdmin || isRoot;

  return (
    <Routes>
      <Route path="/" element={<AnnouncementsList />} />

      {canManageAnnouncements && (
        <>
          <Route path="/new" element={<AnnouncementsNew />} />
          <Route path="/:id" element={<AnnouncementsDetail />} />
        </>
      )}
    </Routes>
  );
}
