import { TenantResponse } from "api/auth";
import { createContext, useContext } from "react";

import { useAuth } from "./AuthContext";

type TenantFeatures = keyof TenantResponse["features"];

export type FeatureFlag = TenantFeatures;

interface FeatureFlagsState {
  features: FeatureFlag[];
  isFeatureFlagEnabled: (flag: FeatureFlag) => boolean;
}

const initialState: FeatureFlagsState = {
  features: [],
  isFeatureFlagEnabled: () => false,
};

export const FeatureFlagsContext = createContext(initialState);

interface FeatureFlagsProviderProps {
  children: React.ReactNode;
}

export const FeatureFlagsProvider = (props: FeatureFlagsProviderProps) => {
  const { tenant } = useAuth();
  const features = (
    tenant?.features
      ? Object.entries(tenant.features)
          .filter(([, value]) => Boolean(value))
          .map(([key]) => key)
      : []
  ) as FeatureFlag[];

  const value = {
    isFeatureFlagEnabled: (flag: FeatureFlag) => {
      return features.includes(flag);
    },
    features,
  };

  return <FeatureFlagsContext.Provider value={value} {...props} />;
};

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);
  if (context === undefined) {
    throw new Error(`useFeatureFlags must be used within FeatureFlagsProvider`);
  }
  return context;
};
