import {
  AddToCartResponse,
  BuyersResponse,
  CancelCartResponse,
  Cart,
  OrderCartResponse,
  RejectCartResponse,
  RemoveCartItemResponse,
  UpdateCartItemQuantityResponse,
} from "types/cart";

import client from "./httpClient";

// -- Get cart --

/**
 * Return a the current shopping cart.
 *
 * @returns Cart or null
 */
export const currentCart = async (): Promise<Cart | null> => {
  const data = await client("GET", "/ui/cart", {});
  return data;
};

/**
 * Order the current shopping cart.
 *
 * @returns OrderCartResponse
 */
export const orderCurrentCart = async (
  buyerId: number,
): Promise<OrderCartResponse> => {
  const data = await client("POST", "/ui/cart", buyerId ? { buyerId } : {});
  return data;
};

/**
 * Order the forwarded shopping cart.
 *
 * @returns OrderCartResponse
 */
export const orderCartById = async (id: number): Promise<OrderCartResponse> => {
  const data = await client("POST", `/ui/cart/${id}`, {});
  return data;
};

/**
 * Add a product to the shopping cart.
 *
 * @param productId product to add
 * @param quantity desired quantity
 * @returns
 */
export const addProductToCart = async (
  productId: string,
  offerId: string,
  quantity: number,
): Promise<AddToCartResponse> => {
  const data = await client("POST", "/ui/cart:add", {
    productId,
    offerId,
    quantity,
  });
  return data;
};

/**
 * Update the quantity of a specific item in the current shopping cart.
 *
 * @param itemId cart item identifier
 * @param quantity new quantity
 * @returns
 */
export const updateCartItemQuantity = async (
  itemId: number,
  quantity: number,
): Promise<UpdateCartItemQuantityResponse> => {
  const data = await client("PUT", "/ui/cart:updateQuantity", {
    itemId,
    quantity,
  });
  return data;
};

/**
 * Remove a specific item from a shopping cart.
 *
 * @param itemId cart item identifier to remove
 * @returns
 */
export const removeCartItem = async (
  itemId: number,
): Promise<RemoveCartItemResponse> => {
  const data = await client("DELETE", "/ui/cart:remove", {
    itemId,
  });
  return data;
};

/**
 * Cancel a shopping cart. This is typically done by a requisitioner
 * that has forwarded a cart and wants to cancel it, even before the manager
 * has approved it.
 *
 * @returns CancelCartResponse
 */
export const cancelCartById = async (
  id: number,
): Promise<CancelCartResponse> => {
  const data = await client("POST", `/ui/cart/${id}:cancel`, {});
  return data;
};

/**
 * Reject a shopping cart. This is typically done by a manager that reviewed
 * a cart and decides to reject it.
 *
 * @returns RejectCartResponse
 */
export const rejectCartById = async (
  id: number,
): Promise<RejectCartResponse> => {
  const data = await client("POST", `/ui/cart/${id}:reject`, {});
  return data;
};

export const bulkAddProductsToCart = async (
  products: {
    productId: string;
    offerId: string;
    quantity: number;
  }[],
): Promise<AddToCartResponse> =>
  await client("POST", "/ui/cart:bulkAdd", { products });

/**
 * Return the shopping cart items.
 *
 * @returns Cart or null
 */
export const getCartItemsByCartId = async (
  cartId: number,
): Promise<Cart | null> => {
  const data = await client("GET", `/ui/cart/${cartId}:currentOffers`, {});
  return data;
};
