import { TenantsSearchResponse } from "lib/tenant";
import { createContext, useContext, useMemo } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";

export interface TenantsState {
  error?: any;
  tenants?: TenantsSearchResponse;
  isValidating: boolean;
}

/**
 * TenantsContext implements a React Context.
 */
export const TenantsContext = createContext<TenantsState | undefined>(
  undefined
);

/**
 * Props of the TenantsProvider component.
 */
interface TenantsProviderProps {
  children: React.ReactNode;
}

/**
 * TenantsProvider is a React Context Provider responsible for managing tenants.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const TenantsProvider = (props: TenantsProviderProps) => {
  const [searchParams] = useSearchParams();
  const sp = createSearchParams(searchParams);
  const { error, data, isValidating } = useSWR<TenantsSearchResponse>(
    `/ui/admin/tenants?${sp.toString()}`
  );

  const value = useMemo(() => {
    return {
      error,
      tenants: data,
      isValidating,
    };
  }, [error, data, isValidating]);

  return <TenantsContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by TenantsProvider.
 * TenantsContext returns.
 *
 * @returns TenantsContext
 */
export const useTenants = () => {
  const context = useContext(TenantsContext);
  if (context === undefined) {
    throw new Error(`useTenants must be used within TenantsProvider`);
  }
  return context;
};
