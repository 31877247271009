import { Cart } from "types/cart";
import { Status } from "lib/status";
import { createContext, useContext, useMemo } from "react";
import useSWR from "swr";

export interface StatusState {
  cart?: Cart;
  awaitingReview?: Cart[];
  awaitingApproval?: Cart[];
  error?: any;
  isLoading: boolean;
}

const initialState: StatusState = {
  isLoading: false,
};

/**
 * StatusContext implements a React Context.
 */
export const StatusContext = createContext<StatusState>(initialState);

/**
 * Props of the StatusProvider component.
 */
interface StatusProviderProps {
  children: React.ReactNode;
}

/**
 * StatusProvider is a React Context Provider responsible for retrieving
 * overall user and application status. It e.g. provides information about
 * the current cart and the number of forwarded carts.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const StatusProvider = (props: StatusProviderProps) => {
  const { data, error, isValidating } = useSWR<Status>("/ui/status");

  const value = useMemo(() => {
    return {
      cart: data?.cart,
      awaitingReview: data?.carts?.awaitingReview,
      awaitingApproval: data?.carts?.awaitingApproval,
      error,
      isLoading: isValidating,
    };
  }, [data, error, isValidating]);

  return <StatusContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by StatusProvider.
 * StatusContext returns e.g.:
 *
 * - cart
 * - forwardedCarts
 *
 * @returns StatusContext
 */
export const useStatus = () => {
  const context = useContext(StatusContext);
  if (context === undefined) {
    throw new Error(`useStatus must be used within StatusProvider`);
  }
  return context;
};
