import { UpdateUserSettingsRequest, UserSettings } from "lib/userSettings";
import client from "./httpClient";

// -- Read and update user settings --

/**
 * Return the current user's settings.
 *
 * @returns UserSettings or null
 */
export const userSettings = async (): Promise<UserSettings> => {
  const data = await client("GET", "/ui/settings", {});
  return data;
};

/**
 * Update the current user's settings.
 *
 * @returns UserSettings
 */
export const updateUserSettings = async (
  request: UpdateUserSettingsRequest
): Promise<UserSettings> => {
  const data = await client("PUT", "/ui/settings", request);
  return data;
};

/**
 * Update the current user's language.
 *
 * @returns UserSettings
 */
export const updateUserLanguage = async (
  language: string
): Promise<UserSettings> => {
  const data = await client("PUT", `/ui/language?code=${language}`, {});
  return data;
};
