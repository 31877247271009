import { createVendor, deleteVendor, patchVendor } from "api/vendor";
import {
  CreateVendorRequest,
  CreateVendorResponse,
  PatchVendorRequest,
  PatchVendorResponse,
  Vendor,
} from "lib/vendor";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";

export interface VendorState {
  vendorId: number;
  error?: any;
  vendor?: Vendor;
  isValidating: boolean;
  refresh: () => Promise<void>;
  createVendor: (request: CreateVendorRequest) => Promise<CreateVendorResponse>;
  patchVendor: (request: PatchVendorRequest) => Promise<PatchVendorResponse>;
  deleteVendor: () => Promise<any>;
}

const initialState: VendorState = {
  vendorId: 0,
  isValidating: false,
  refresh: () => Promise.reject(),
  createVendor: () => Promise.reject(),
  patchVendor: () => Promise.reject(),
  deleteVendor: () => Promise.reject(),
};

/**
 * VendorContext implements a React Context.
 */
export const VendorContext = createContext<VendorState>(initialState);

/**
 * Props of the VendorProvider component.
 */
interface VendorProviderProps {
  children: React.ReactNode;
}

/**
 * VendorProvider is a React Context Provider responsible for managing vendors.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const VendorProvider = (props: VendorProviderProps) => {
  let { vendorId } = useParams();

  const fetchResult = useSWR<Vendor>(
    vendorId && `/ui/admin/vendors/${vendorId}`
  );

  const refresh = useCallback(async () => {
    fetchResult.mutate();
  }, [fetchResult]);

  const doDeleteVendor = useCallback(async () => {
    const id = Number(vendorId);
    if (id) {
      await fetchResult.mutate();
      return deleteVendor(id);
    }
    return Promise.reject();
  }, [vendorId, fetchResult]);

  const doPatchVendor = useCallback(
    async (request: PatchVendorRequest) => {
      const id = Number(vendorId);
      if (id) {
        await fetchResult.mutate();
        return patchVendor(id, request);
      }
      return Promise.reject();
    },
    [vendorId, fetchResult]
  );

  const value = useMemo(() => {
    return {
      vendorId: Number(vendorId),
      error: fetchResult.error,
      vendor: fetchResult.data,
      isValidating: fetchResult.isValidating,
      refresh,
      createVendor,
      patchVendor: doPatchVendor,
      deleteVendor: doDeleteVendor,
    };
  }, [vendorId, fetchResult, refresh, doPatchVendor, doDeleteVendor]);

  return <VendorContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by VendorProvider.
 * VendorContext returns.
 *
 * @returns VendorContext
 */
export const useVendor = () => {
  const context = useContext(VendorContext);
  if (context === undefined) {
    throw new Error(`useVendor must be used within VendorProvider`);
  }
  return context;
};
