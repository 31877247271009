import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { Locale } from "utils/locale";

i18n
  .use(LanguageDetector)
  .use(
    resourcesToBackend((language: string) => {
      return import(`./translations/${language}.json`);
    }),
  )
  .use(initReactI18next)
  .init({
    partialBundledLanguages: true,
    fallbackLng: Locale.en,
    debug: false,
    supportedLngs: Object.values(Locale),
    detection: {
      order: ["querystring", "localStorage", "navigator"],
      lookupLocalStorage: "lang",
      lookupQuerystring: "lang",
      caches: ["localStorage"],
    },
    react: {
      useSuspense: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: [
        "br",
        "p",
        "strong",
        "i",
        "h1",
        "h2",
        "h3",
        "h4",
      ],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
