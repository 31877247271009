import { createContext } from "react";
import { Navigate } from "react-router";
import { useAuth } from "./AuthContext";

/**
 * AdminContext implements a React Context.
 */
export const AdminContext = createContext({});

/**
 * Props of the AdminProvider component.
 */
interface AdminProviderProps {
  children: React.ReactNode;
}

/**
 * AdminProvider is a React Context Provider responsible for
 * protecting the Admin section of the application.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const AdminProvider = (props: AdminProviderProps) => {
  const { isRoot, isAdmin } = useAuth();

  if (!(isRoot || isAdmin)) {
    <Navigate to="/" />;
  }

  return <AdminContext.Provider value={{}} {...props} />;
};
