import * as Sentry from "@sentry/react";
import type { Error as ApiError } from "api/error";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const SENTRY_DSN =
  "https://92f7f8026ee9886df6c962a84ed675c9@o4507383241441280.ingest.de.sentry.io/4507383290265680";

export const init = () => {
  const sentryEnvironment = import.meta.env.VITE_SENTRY_ENV;
  const sentryRelease = import.meta.env.VITE_BUILD_TAG;
  const shouldEnableSentry = Boolean(sentryEnvironment);

  if (shouldEnableSentry) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: sentryEnvironment,
      release: sentryRelease,
      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      beforeSend: (event, hint) => {
        // Ignore unhandled rejections caused by `fetch` other thann 500 / InternalServerError
        if (hint.mechanism?.type === "onunhandledrejection") {
          const originalException = hint.originalException as ApiError;

          // Check if this is really an ApiError by verifiying the presence of `__meta.statusCode` property
          if (
            originalException?.__meta?.statusCode &&
            originalException?.__meta?.statusCode < 500
          ) {
            return null;
          }
        }

        return event;
      },
    });

    import("@sentry/browser").then((lazyLoadedSentry) => {
      Sentry.addIntegration(lazyLoadedSentry.replayIntegration());
    });
  }
};
